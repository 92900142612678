import downloadPic from '../assets/images/brands/Download_app.svg';
import instagram from '../assets/images/brands/Instagram_Glyph_Black.svg';
import linkedin from '../assets/images/brands/linkedin.png';
import twitter from '../assets/images/brands/X_black.png';

/**
 * Stack the elements like this:
 * At desktop (>=992px):
 * Copyright 2024 Nova. All rights reserved / ins linkedin X App / store
 * 
 * At mobile devices (<992px):
 * App store
 * ins linkedin X
 * Copyright 2024 Nova. All rights reserved
 * @returns 
 */
function Copyright() {
    return (
        <>
            <div className="row px-5 ps-lg-0 pb-3 pb-lg-5 pt-1 pt-lg-0 d-flex flex-column-reverse align-items-center align-items-lg-end flex-lg-row ">
                <div className="col-lg-4 text-start text-lg-center">
                    <p className="glowva-text-xs mb-0 pt-3">Copyright 2024 Brightify Labs LLC. All rights reserved</p>
                </div>

                <div className="col-lg-8 d-flex flex-row">

                    <div className="col-6 text-center d-flex flex-row align-items-end justify-content-lg-center justify-content-start">
                        <a href="https://www.instagram.com/glowva_4_parents" target="_blank" rel="noreferrer">
                            <img src={instagram} alt="instagram link" className="brand-logo mx-3" />
                        </a>
                        <a href="https://www.linkedin.com/company/glow-and-grow/" target="_blank" rel="noreferrer">
                            <img src={linkedin} alt="linkedin link" className="brand-logo mx-3" />
                        </a>
                        <a href="https://x.com/" target="_blank" rel="noreferrer">
                            <img src={twitter} alt="twitter link" className="brand-logo mx-3" />
                        </a>
                    </div>

                    <div className="col-6 text-end">
                        <a href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer"><img src={downloadPic} alt="app download link" /></a>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Copyright;